import React from "react"

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"


const stripePromise = loadStripe(process.env.STRIPE_KEY)

export const wrapRootElement = ({ element }) => {
    return <Elements stripe={stripePromise}>{element}</Elements>
}

